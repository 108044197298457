import React, { useState } from 'react'
import Calendly from './Calendly'

function BlocImageFullWidth(props) {
  const [popupCalendly, setPopupCalendly] = useState(false)

  const closePopupCalendly = () => {
    setPopupCalendly(false)
  }

  const {
    headline,
    image,
    description,
    lienTitreCta
  } = props.data

  return (
    <section className="section-image-full-witdh" style ={{ backgroundImage: "url("+image.file.url+")" }}>
      <div className="container">
        <div className="row">
          <div className="columns col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12"></div>
          <div className="columns col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
            <div className="box-item">
              <h2 className="extra-large">{headline}</h2>
              <p>{description}</p>
              <button onClick={(e) => setPopupCalendly(true)} className="btn btn-secondary btn-blanc"><span></span>{lienTitreCta}</button>
            </div>
          </div>
        </div>
      </div>
      <Calendly data={popupCalendly} closePopup={closePopupCalendly}/>
    </section>
  )
}

export default BlocImageFullWidth