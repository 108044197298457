import React from 'react'
import { Link } from 'gatsby'

function BlocJauneTexte(props) {
  const {
    headline,
    image,
    texte,
    lienInterne,
    lienTitreCta
  } = props.data

  return (
    <div>
      {headline !== ' ' ?
      <section className="section-modulaire-jaune-texte">
        <div className="container">
          <div className="row">
            <div className="columns col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <div className="box-item">
                <h2 className="large">{headline}</h2>
                <p>{texte.texte}</p>
                <Link to={lienInterne} className="btn btn-secondary btn-blanc tundora"><span></span>{lienTitreCta}</Link>
              </div>
            </div>
            <div className="columns col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12" style={{ backgroundImage: "url("+image.file.url+")" }}></div>
          </div>
        </div>
      </section> : ''}
    </div>
  )
}

export default BlocJauneTexte
