import React from 'react'
import AliceCarousel from 'react-alice-carousel'

export class CarouselFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carousel: this.props.data.features[0].id
    }
  }

  toggle = (position) => {
    this.setState({carousel : null})
    this.setState({carousel : position})
  }

  onChecked = (position) => {
    if (this.state.carousel === position) {
      return 'checked';
    }
    return ''
  }

  componentWillReceiveProps(nextProps, data) {
    this.setState({
      carousel: nextProps.data.features[0].id
    })
  }

  render() {
    const carouselFeature = this.props.data
    const {responsive} = this.state

    return (
      <div>
      <div className="carousel">
        <div className="row">
          <div className="columns col-lg-6 col-md-6 col-sm-12 col-12">
            <ul className="tabs">
              {carouselFeature.features
                .filter((item) => item.__typename !== 'ContentfulFeatures' && item.node_locale === this.state.language)
                .map((item) =>
                  <li key={item.id} className={this.onChecked(item.id)}>
                    <div role="row" tabIndex={0} onMouseEnter={() => {this.toggle(item.id)}}>
                      <h3>{item.headline}</h3>
                      <p>{item.description}</p>
                    </div>
                  </li>
              )}
            </ul>
          </div>
          <div className="columns col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
            {carouselFeature.features
              .filter((item) => item.__typename !== 'ContentfulFeatures')
              .map((item) =>
                <div key={item.id} className={`tab-content ${this.onChecked(item.id)}`}>
                  {item.image !== null ? <img src={item.image.file.url} alt={item.image.description}/> : ''}
                </div>
            )}
          </div>
        </div>
      </div>

      <div className="carousel-features-mobile">
        <AliceCarousel
          dotsDisabled={false}
          buttonsDisabled={false}
          responsive={responsive}
          ref={(el) => (this.Carousel = el)}
          mouseDragEnabled >

          {carouselFeature.features
            .filter((item) => item.__typename !== 'ContentfulFeatures' && item.node_locale === this.state.language)
            .map((item) =>
              <div className="item" key={item.id}>
                {item.image !== null ? <img src={item.image.file.url} alt={item.image.description}/> : ''}
                <h3>{item.headline}</h3>
                <p>{item.description}</p>
              </div>
          )}
        </AliceCarousel>
      </div>
    </div>
    )
  }
}

export default CarouselFeatures
