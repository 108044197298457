import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import AliceCarousel2 from 'react-alice-carousel'

export class SliderFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: 0,
      currentIndex: 0,
      responsive: {
        1100: { items: 3 },
        991: { items: 3 },
        990: { items: 1 },
        0: { items: 1 }
      }
    }
  }

  componentDidMount() {
    if (typeof sessionStorage !== 'undefined') {
      if(sessionStorage.getItem('language') === '0') {
        this.setState({
          language: 0
        })
      } if(sessionStorage.getItem('language') === '1') {
        this.setState({
          language: 1
        })
      }
    }
  }

  componentWillReceiveProps(nextProps, language) {
    if (typeof sessionStorage !== 'undefined') {
      if((sessionStorage.getItem('language') === '0') || (nextProps.language === 0)) {
        this.setState({
          language: 0
        })
      } if((sessionStorage.getItem('language') === '0') || (nextProps.language === 0)) {
        this.setState({
          language: 1
        })
      }
    }
    this.componentDidMount()
  }

  prevBloc() {
    this.Carousel.slidePrev()
    this.Carousel2.slidePrev()
  }

  nextBloc() {
    this.Carousel.slideNext()
    this.Carousel2.slideNext()
  }

  prevBlocSingle() {
    this.Carousel.slidePrev()
  }

  nextBlocSingle() {
    this.Carousel.slideNext()
  }

  render() {
    const sliderFeatures = this.props.data.features
    const {responsive} = this.state
    const firstHalfNbrSliderFeatures = Math.floor((sliderFeatures.length) / 2)

    return (
      <div>
        <section className="section-slide-features mobile-row">
          <div className="container">
            <div className="row">
              <div className="columns col-lg-12">
                <h2 className="medium">{this.props.data.headline}</h2>
              </div>
            </div>

            <AliceCarousel
              dotsDisabled={false}
              buttonsDisabled={false}
              responsive={responsive}
              ref={(el) => (this.Carousel = el)}
              mouseDragEnabled >

              {sliderFeatures.map((item) =>
                <div className="item" key={item.headline}>
                  <div className="box-bloc">
                    <div className="row">
                      <div className="columns col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5">
                        <h3>{item.headline}</h3>
                        <p className="lien"><a href={item.lien}>{item.node_locale === 'en-US' ? 'See details' : 'Voir les détails' }</a></p>
                      </div>
                      <div className="columns col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7">
                        {item.imageSlider !== null ? <img src={item.imageSlider.file.url} alt={item.description}/> : '' }
                      </div>
                    </div>
                  </div>
                </div>
            )}
            </AliceCarousel>
          </div>
        </section>

      {sliderFeatures.length < 5 ?
        <section className="section-slide-features single-row">
          <div className="container">
            <div className="row" id="row">
              <div className="columns col-lg-11">
                <h2 className="medium">{this.props.data.headline}</h2>
              </div>
              <div className="columns col-lg-1">
                <ul className="prev-next">
                  <li onClick={(event) => this.prevBlocSingle(event)} />
                  <li onClick={(event) => this.nextBlocSingle(event)} />
                </ul>
              </div>
            </div>

            <AliceCarousel
              dotsDisabled={false}
              buttonsDisabled={false}
              responsive={responsive}
              ref={(el) => (this.Carousel = el)}
              mouseDragEnabled >

              {sliderFeatures.map((item) =>
                <div className="item" key={item.headline}>
                  <div className="box-bloc">
                    <div className="row">
                      <div className="columns col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5">
                        <h3>{item.headline}</h3>
                        <p className="lien"><a href={item.lien}>{item.node_locale === 'en-US' ? 'See details' : 'Voir les détails' }</a></p>
                      </div>
                      <div className="columns col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7">
                        {item.imageSlider !== null ? <img src={item.imageSlider.file.url} alt={item.description}/> : '' }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </AliceCarousel>
          </div>
        </section>
        :
        <section className="section-slide-features">
          <div className="container">
            <div className="row" id="row">
              <div className="columns col-lg-11">
                <h2 className="medium">{this.props.data.headline}</h2>
              </div>
              <div className="columns col-lg-1">
                <ul className="prev-next">
                  <li onClick={(event) => this.prevBloc(event)} />
                  <li onClick={(event) => this.nextBloc(event)} />
                </ul>
              </div>
            </div>

            <AliceCarousel
              dotsDisabled={false}
              buttonsDisabled={false}
              responsive={responsive}
              ref={(el) => (this.Carousel = el)}
              mouseDragEnabled >

              {sliderFeatures.slice(0,firstHalfNbrSliderFeatures).map((item) =>
                <div className="item" key={item.headline}>
                  <div className="box-bloc">
                    <div className="row">
                      <div className="columns col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5">
                        <h3>{item.headline}</h3>
                        <p className="lien"><a href={item.lien}>{item.node_locale === 'en-US' ? 'See details' : 'Voir les détails' }</a></p>
                      </div>
                      <div className="columns col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7">
                        {item.imageSlider !== null ? <img src={item.imageSlider.file.url} alt={item.description}/> : '' }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </AliceCarousel>

            <AliceCarousel2
              dotsDisabled={false}
              buttonsDisabled={false}
              responsive={responsive}
              ref={(el) => (this.Carousel2 = el)}
              mouseDragEnabled >

              {sliderFeatures.slice(firstHalfNbrSliderFeatures,99).map((item) =>
                <div className="item" key={item.headline}>
                  <div className="box-bloc">
                    <div className="row">
                      <div className="columns col-lg-5 col-md-5 col-sm-5 col-xs-5 col-5">
                        <h3>{item.headline}</h3>
                        <p className="lien"><a href={item.lien}>{item.node_locale === 'en-US' ? 'See details' : 'Voir les détails' }</a></p>
                      </div>
                      <div className="columns col-lg-7 col-md-7 col-sm-7 col-xs-7 col-7">
                        {item.imageSlider !== null ? <img src={item.imageSlider.file.url} alt={item.description}/> : '' }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </AliceCarousel2>
          </div>
        </section>}
      </div>
    )
  }
}

export default SliderFeatures
